.zawodnicy-section {
    background-color: #010101;
    color: white;
    text-align: center;
    position: relative;
}

/* CSS Slider */
.slider {
    height: 80vh;
    margin-top: -32px;
    position: relative;
}

.slider .list .item {
    position: absolute;
    inset: 0;
    overflow: hidden;
    opacity: 0;
    transition: .5s;
}

.slider .list .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider .list .item::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(to top, #000 30%, transparent);
}

.slider .list .item .content {
    position: absolute;
    left: 10%;
    top: 12%;
    width: 500px;
    max-width: 80%;
    z-index: 1;
}

.slider .list .item .content p {
    font-size: 1.2rem; /* Powiększenie czcionki dla paragrafów */
    color: #fff; /* Ustaw kolor tekstu (jeśli potrzebne) */
}

.slider .list .item .content h2 {
    font-size: 3rem; /* Powiększenie czcionki dla nagłówków */
    color: orange; /* Ustaw kolor tekstu (jeśli potrzebne) */
    margin: 0.5rem 0; /* Marginesy dla lepszej czytelności */
}

.slider .list .item.active {
    opacity: 1;
    z-index: 5;
}

.thumbnail {
    position: absolute;
    bottom: 50px;
    z-index: 10;
    display: flex;
    gap: 10px;
    width: 100%;
    height: 250px;
    padding: 0 50px;
    box-sizing: border-box;
    overflow: auto;
    justify-content: center;
}

.thumbnail .item {
    width: 150px;
    height: 220px;
    filter: brightness(.5);
    transition: .5s;
    flex-shrink: 0;
}

.thumbnail .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.thumbnail .item.active {
    filter: brightness(1.05);
}

/* Stylowanie dla małych ekranów (np. telefonów komórkowych) */
@media (max-width: 768px) {
    .thumbnail {
        display: none; /* Ukryj miniaturki na małych ekranach */
    }

    .slider .list .item .content {
        position: absolute;
        width: 500px;
        max-width: 80%;
        z-index: 1;
        bottom: 0;
        padding-top: 14rem;
    }
    .arrows {
        position: relative;
        top: 50%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        transform: translateY(-50%);
        z-index: 10;
        padding-left: 10px;
        padding-right: 10px;
    }

    .arrows button {
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        border: none;
        padding: 10px;
        cursor: pointer;
    }
}
