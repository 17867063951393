/* Global styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: monospace, sans-serif;
  }
  
  /* Stylizacja sekcji Hero */
  .hero {
    position: relative;
    background-image: url('https://images.pexels.com/photos/133325/pexels-photo-133325.jpeg?auto=compress&cs=tinysrgb&w=1600');
    background-size: cover;
    background-position: center;
    height: 80vh;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Przyciemnienie obrazu */
    z-index: 1;
  }
  
  .hero-content {
    position: relative;
    z-index: 2; /* Zapewnia, że treść jest nad przyciemnieniem */
  }
  
  .hero h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    position: relative;
    color: white;
    transition: color 0.3s ease;
    background: linear-gradient(
      100px circle at var(--x) var(--y),
      orange 25%,
      white 40%
    );
    -webkit-background-clip: text;
    background-clip: text; /* Standardowa definicja */
  }
  
  .hero p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  
  .btn {
    background-color: #00bfff;
    color: white;
    font-size: 1.2rem;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: monospace;
    display: block;        /* Ustawienie na blok */
    margin: 50px auto;
}
  
  .btn:hover {
    background-color: orange;
  }
  
  /* Efekt hover */
  .hover-effect {
    --x: 0px;
    --y: 0px;
    background: linear-gradient(
      100px circle at var(--x) var(--y),
      orange 25%,
      white 40%
    );
    -webkit-background-clip: text;
    background-clip: text; /* Standardowa definicja */
    color: transparent;
    transition: background-position 0.3s ease;
  }
  
  