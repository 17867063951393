.miejsce-section {
    padding: 50px 0;
    background-color: #000000; /* Zmieniamy tło na czarne */
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

.miejsce-title {
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff; /* Zmieniamy kolor tekstu na biały */
}

.miejsce-description {
    font-size: 18px;
    text-align: center;
    margin-bottom: 40px;
    color: #ffffff;
}

.map-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%; /* Współczynnik proporcji 16:9 dla iframe */
    margin: 0 auto;
    max-width: 100%;
    border-radius: 10px;
}

.map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

/* Stylowanie responsywne */
@media (max-width: 768px) {
    .miejsce-title {
        font-size: 28px;
    }

    .miejsce-description {
        font-size: 16px;
    }
}
