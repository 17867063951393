.navbar {
    background-color: black;
    color: white;
    padding: 15px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    display: flex;
    justify-content: space-around;
}

.navbar button {
    background: none;
    font-family: monospace;
    border: none;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: color 0.3s;
}

.navbar button:hover {
    color: #00bfff;
}
