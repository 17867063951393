/* Info section */
.info-section {
  padding: 4rem 1rem;
  height: 80vh;
  background-color: #f4f4f9;
}

.info-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.info-description {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 3rem;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
}

/* Match cards */
.matches {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* max 3 in a row */
  gap: 1.5rem;
}

.match-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.match-card:hover {
  transform: translateY(-5px);
}

.match-logo img {
  width: 90px;
  height: 80px;
  margin-right: 1rem;
  border-radius: 8px;
  display: flex;
}

.match-info {
  flex-grow: 1;
}

.match-date {
  font-size: 1rem;
  color: #fff;
}

.match-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-top: 0.5rem;
  white-space: nowrap;
}

.match-status {
  font-size: 0.9rem;
  margin-top: 0.5rem;
  color: #fff;
  font-weight: bold;
}

/* Status colors */
.match-card.zakończone {
  background-color: #e74c3c;  /* Red for finished matches */
}

.match-card.zaplanowane {
  background-color: #2ecc71;  /* Green for upcoming matches */
}


@media (max-width: 768px) {
  .matches {
    grid-template-columns: 1fr; /* 1 in a row on very small screens */
  }

  .info-section {
    height: 120vh;
  }
}
