.tabela-turnieju-section {
    padding: 50px 0;
    background-color: #f9f9f9;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

.tabela-title {
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.tabela-wrapper {
    overflow-x: auto; /* Dodaj przewijanie poziome */
}

.tabela {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    font-size: 18px;
}

.tabela th, .tabela td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.tabela th {
    background-color: #4CAF50;
    color: white;
}

.tabela tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Stylowanie responsywne dla mniejszych ekranów */
@media (max-width: 768px) {
    .tabela {
        display: block; /* Zmień tabelę na blok */
    }

    .tabela thead {
        display: none; /* Ukryj nagłówki tabeli */
    }

    .tabela tbody {
        display: block;
    }

    .tabela tbody tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        padding: 10px;
        border: 1px solid #ddd;
        background-color: #fff;
        border-radius: 8px;
    }

    .tabela tbody tr td {
        display: flex;
        justify-content: space-between;
        padding: 8px 0;
        border: none;
        font-size: 16px;
    }

    .tabela tbody tr td::before {
        content: attr(data-label); /* Użyj atrybutu data-label */
        font-weight: bold;
        margin-right: 10px;
    }
}
