/* Stylizacja dla przycisku strzałki */
.scroll-to-top {
    position: fixed;
    bottom: 30px; /* Umiejscowienie w prawym dolnym rogu */
    right: 30px;
    width: 50px;
    height: 50px;
    font-size: 24px;
    background-color: #4CAF50; /* Zielony kolor przycisku */
    color: white;
    border: none;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Delikatny cień */
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease; /* Płynne pojawianie się i znikanie */
    z-index: 1000; /* Umieszczamy strzałkę nad innymi elementami */
}

.scroll-to-top:hover {
    background-color: #45a049; /* Zmiana koloru przy hover */
}

.scroll-to-top.show {
    opacity: 1;
    visibility: visible;
}

section {
    min-height: 80vh; 
    padding-top: 2rem;
    padding-bottom: 2rem;
    align-content: center;
}


/* Responsywność dla mniejszych ekranów */
@media (max-width: 768px) {
    section {
        padding: 30px 0; /* Zmniejszenie paddingu dla mniejszych ekranów */
    }
}
